@import 'styles/colors';
@import 'styles/typography';

.muni-logo {
  display: inline-block;
  height: $font-size-h2;
  margin-bottom: 5px;
  vertical-align: middle;
}

.route {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 2;
  align-items: center;
  margin-bottom: 20px;
}

.route .col {
  grid-row: 1 / span 2;
}

.route .routeNumber {
  display: inline-block;
  font: bold 32px Montserrat, sans-serif;

  width: 1.6em;
  height: 1.6em;
  line-height: 1.6em;

  background: #fff;
  color: black;
  text-align: center;
  border-radius: 50%;
}

.route .routeDirection {
  grid-column-start: 2;
  font-size: $font-size-base;
  margin-left: 15px;
}

.route .leaveTitle {
  grid-column-start: 3;
  text-align: center;
  font-size: $font-size-sm;
  font-weight: bold;
  text-transform: uppercase;
}

.route .leaveTimes {
  grid-column-start: 3;
  grid-row-start: 2;
  font-size: $font-size-h5;
}

ul.leaveTimes {
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
}

ul.leaveTimes li {
  padding-bottom: 10px;
  position: relative;
}
