.widget {
  background: #111;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 40vw;
  transition: all 200ms ease;

  .widget-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  & > .widget {
    margin-top: 10px;
    width: inherit;
    padding: 0;
  }
}

.bottom > .widget {
  min-width: 40vw;
}

.top > .widget {
  width: inherit;
}

body.orientation-left .bottom > .widget,
body.orientation-right .bottom > .widget {
  min-width: 40vh;
}

/* .widget-active {
  position: absolute;
  z-index: 5;
  left: 50%;
  margin-left: -35vw;
  top: 50%;
  margin-top: -35vw;
  width: 70vw;
  border: 3px solid $activeBorderColor;
} */
