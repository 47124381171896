@import 'styles/colors';
@import 'styles/typography';

.widget-weather {
  width: inherit;
}

.currently {
  display: flex;
  align-items: center;
}

.temperature {
  font-size: $font-size-h1;
  line-height: 0.9;
}

.icon-wrapper {
  display: grid;
  grid-template-rows: 1fr 1.6rem;
  align-items: center;

  .high-low {
    text-align: center;
    font-size: $font-size-base;
    font-weight: bold;

    .high {
      color: $red;
    }

    .low {
      color: $blue;
    }
  }
}

.feelsLike {
  font-size: $font-size-base;
}

.summary {
  font-size: $font-size-base * 0.8;
  line-height: 1.6;
}

.weather-icon {
  display: inline-block;
  width: $font-size-h1 * 0.75;
  height: $font-size-h1 * 0.75;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.weather-icon.clear-day {
  background-image: url(./icons/clear_day.svg);
}
.weather-icon.clear-night {
  background-image: url(./icons/clear_night.svg);
}
.weather-icon.cloudy {
  background-image: url(./icons/cloudy.svg);
}
.weather-icon.fog {
  background-image: url(./icons/fog.svg);
}
.weather-icon.partly-cloudy-day {
  background-image: url(./icons/partly_cloudy_day.svg);
}
.weather-icon.partly-cloudy-night {
  background-image: url(./icons/partly_cloudy_night.svg);
}
.weather-icon.rain {
  background-image: url(./icons/rain.svg);
}
.weather-icon.sleet {
  background-image: url(./icons/sleet.svg);
}
.weather-icon.snow {
  background-image: url(./icons/snow.svg);
}
.weather-icon.wind {
  background-image: url(./icons/wind.svg);
}
