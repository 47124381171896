@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

$font-size-base: 1.25rem;
$font-size-sm: 0.95rem;
$font-size-xs: 0.75rem;

$font-size-h1: 8rem;
$font-size-h2: 1.8rem;
$font-size-h3: 1.65rem;
$font-size-h4: 1.6rem;
$font-size-h5: 1.5rem;
$font-size-h6: $font-size-sm;

$line-height-base: 2.4;
$baseline: $line-height-base * 1rem;
