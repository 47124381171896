$white: #fff;
$black: #000;
$red: #ff4136;
$blue: #0074d9;

$lightGray: #cacaca;

$lyftPrimary: #ff00bf;
$lyftSecondary: #8b37ff;

$citibikePrimary: #0098e4;
$citibikeSecondary: #ed2e14;
