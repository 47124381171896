@import 'styles/colors';
@import 'styles/typography';

$green: rgb(0, 228, 0);
$yellow: rgb(255, 255, 0);
$orange: rgb(255, 126, 0);
$red: rgb(255, 0, 0);
$purple: rgb(156, 28, 235);
$maroon: rgb(126, 0, 35);

.widget-air-quality {
  margin-top: -20px !important;
  font-size: 0.8rem;
  text-align: right;

  .air-quality-bar {
    position: relative;
  }

  .bar {
    position: relative;
    background: transparent;
    height: 8px !important;
    overflow: hidden;
    border: 0;
    border-radius: 5px;
    height: 1rem;
    margin-top: 5px;
    background: linear-gradient(
      90deg,
      $green 0%,
      $yellow 14.29%,
      $orange 28.87%,
      $red 43.14%,
      $purple 57.43%,
      $maroon 100%
    );
  }

  .knob {
    position: absolute;
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    border: 2px solid #111;
    border-radius: 6px;
    z-index: 1;
    top: -2px;
    left: 0;
  }
}
