.widget-schedule {
  width: 40vw;

  ul {
    font-size: 1em;
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
