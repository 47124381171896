@import 'styles/typography';

.widget-clock {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: inherit;

  .time {
    flex-grow: 1;
    font-size: $font-size-h1;
    line-height: 0.9;
  }

  .date {
    flex-grow: 1;
    font-size: $font-size-h5;
    text-align: center;
  }
}
