@import 'styles/colors';
@import 'styles/typography';

* {
  box-sizing: border-box;
}

html,
body,
div {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 300;
  font-size: $font-size-base;
  font-feature-settings: “liga” on;
  font-variant-numeric: tabular-nums;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;

  background-color: $black;
  color: $white;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body.orientation-right {
  transform: rotate(90deg);
  transform-origin: bottom left;
  top: -100vw;
  height: 100vw;
  width: 100vh;
  position: relative;
}

body.orientation-left {
  transform: rotate(-90deg);
  transform-origin: top right;
  left: -100vh;
  height: 100vw;
  width: 100vh;
  position: relative;
}

h1,
h2,
h3 {
  margin: 15px 0;
  padding: 0;
}

h6 {
  margin: 5px 0;
  font-weight: bold;
}

h5 {
  font-size: $font-size-h5;
  font-weight: normal;
  margin: 5px 0;
}

h4 {
  font-size: $font-size-h4;
  font-weight: bold;
  text-transform: uppercase;
  margin: 15px 0;
}

h3 {
  font-size: $font-size-h3;
}

.app {
  display: grid;
  grid-template-columns: 50vw 50vw;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'top-left top-right'
    'bottom-left bottom-right';
  height: 100vh;
}

body.orientation-left .app,
body.orientation-right .app {
  grid-template-columns: 50vh 50vh;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'top-left top-right'
    'bottom-left bottom-right';
  height: 100vw;
}

.app-menu-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 2000;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
}

.app-menu {
  width: 50vw;
  height: 50vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-button-wrapper {
  position: absolute;
  z-index: 2000;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 33%;
  margin-bottom: 25px;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

body.orientation-left .app-menu,
body.orientation-right .app-menu {
  width: 50vh;
  height: 50vw;
}

.top-left {
  grid-area: top-left;
}

.top-right {
  grid-area: top-right;
}

.bottom-left {
  grid-area: bottom-left;
}

.bottom-right {
  grid-area: bottom-right;
}

.top-left,
.top-right,
.bottom-left,
.bottom-right {
  display: flex;
  flex-direction: column;
}

.top-left,
.bottom-left {
  align-items: flex-start;
}

.top-right,
.bottom-right {
  align-items: flex-end;
}

.top > div:not(:first-child) {
  margin-top: 25px;
}

.bottom {
  flex-direction: column-reverse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
