@import 'styles/typography';

.widget-calendar {
  width: 40vw;
  font-size: $font-size-base;

  .time {
    font-size: $font-size-xs;
    font-weight: 700;
    margin: 0 10px;
    vertical-align: middle;
  }

  i,
  .time {
    color: #aaa;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    text-transform: uppercase;
  }
}
