@import 'styles/colors';
@import 'styles/typography';

$gray: #333;
$red: #f00;
$green: #00933c;
$blue: #0039a6;
$orange: #ff6319;

.widget-subway {
  width: inherit;

  .logo {
    display: inline-block;
    background-image: url(assets/MTA_NYC_logo.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: $font-size-h2;
    width: 1.6455rem;
    margin-right: 10px;
  }

  .subway {
    display: inline-block !important;
    font-family: Helvetica, sans-serif;
    border-radius: 50%;
    background-color: $gray;
    color: $white;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    height: 30px;
    line-height: 31px;
    text-align: center;
    width: 30px;

    &.subway-large {
      height: 90px;
      width: 90px;
      font-size: 75px;
      line-height: 92px;
    }
  }

  .subway-1,
  .subway-2,
  .subway-3 {
    background-color: $red;
  }

  .subway-A,
  .subway-C,
  .subway-E {
    background-color: $blue;
  }

  .subway-B,
  .subway-D,
  .subway-F,
  .subway-M {
    background-color: $orange;
  }

  .subway-4,
  .subway-5,
  .subway-6 {
    background-color: $green;
  }

  .subway-G {
    background-color: #6cbe45;
  }

  .subway-L {
    background-color: #a7a9ac;
  }

  .subway-N,
  .subway-R,
  .subway-Q,
  .subway-W {
    color: black;
    background-color: #fccc0a;
  }

  .subway-7 {
    background-color: #b933ad;
  }

  .subway-J,
  .subway-Z {
    background-color: #996633;
  }

  .subway-S {
    background-color: #808183;
  }

  .departure {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    font-size: 1.25rem;
  }

  .time {
    margin-left: 10px;
  }
}
