@import 'styles/colors';
@import 'styles/typography';

.widget-bikes {
  .logo {
    height: 1.8rem;
    margin-bottom: 10px;
  }

  .station {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    .station-info {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      .station-counts,
      .count {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .count {
        margin-left: 5px;
      }

      .number,
      .name {
        font-size: $font-size-base;
      }

      .name {
        margin-right: 10px;
      }

      .fa {
        margin-left: 5px;
      }
    }
  }

  .ebikes {
    font-weight: bold;
  }

  .progress-bar {
    position: relative;
    border: 1px solid $white;
    background: transparent;
    height: 35px;
    overflow: hidden;
    border: 0;
    border-radius: 4px;
    background: $lightGray;
    height: 1rem;
    margin-top: 5px;
  }

  .progressPrimary,
  .progressSecondary {
    position: absolute;
    background: $lyftPrimary;
    height: 100%;
  }

  .progressSecondary {
    background: $lyftSecondary;
  }

  .fa-bolt {
    margin-right: 5px;
    animation: color_change_baywheels 1s infinite alternate;
  }

  &.citibike {
    .progressPrimary {
      background: $citibikePrimary;
    }
    .progressSecondary {
      background: $citibikeSecondary;
    }
    .fa-bolt {
      margin-right: 5px;
      animation: color_change_citibike 1s infinite alternate;
    }
  }
}

@keyframes color_change_baywheels {
  from {
    color: $lyftSecondary;
  }
  to {
    color: $lyftPrimary;
  }
}

@keyframes color_change_citibike {
  from {
    color: $citibikeSecondary;
  }
  to {
    color: $citibikePrimary;
  }
}
